export const OTHER_CATEGORY_ID = 27;
export const COLLEGE_TER_REPORTING_PERIOD = 'SEA Term-End Expenditure Report';

export const WORKFLOW_METRIC_STEPS = {
  SUCCESSFUL_ENROLLMENT: 'successful-enrollment',
  COMPLETED_MATH_ENGLISH: 'completed-math-english',
  RETENTION: 'retention',
  TRANSFER: 'transfer',
  COMPLETION: 'completion',
  PERSISTENCE: 'persistence'
};

export const WORKFLOW_METRIC_LABELS = {
  [WORKFLOW_METRIC_STEPS.SUCCESSFUL_ENROLLMENT]: 'Successful Enrollment',
  [WORKFLOW_METRIC_STEPS.COMPLETED_MATH_ENGLISH]: 'Completed Transfer-Level Math & English',
  [WORKFLOW_METRIC_STEPS.RETENTION]: 'Persistence: First Primary Term to Secondary Term',
  [WORKFLOW_METRIC_STEPS.TRANSFER]: 'Transfer',
  [WORKFLOW_METRIC_STEPS.COMPLETION]: 'Completion'
};
export const WORKFLOW_METRICS = Object.entries(WORKFLOW_METRIC_LABELS).map(([value, label]) => ({ label, value }));

export const WORKFLOW_METRIC_LABELS_V3 = {
  [WORKFLOW_METRIC_STEPS.SUCCESSFUL_ENROLLMENT]: 'Successful Enrollment',
  [WORKFLOW_METRIC_STEPS.COMPLETED_MATH_ENGLISH]: 'Completed Both Transfer-Level Math & English',
  [WORKFLOW_METRIC_STEPS.PERSISTENCE]: 'Persistence: First Primary Term to Secondary Term',
  [WORKFLOW_METRIC_STEPS.COMPLETION]: 'Completion',
  [WORKFLOW_METRIC_STEPS.TRANSFER]: 'Transferred to a Four-Year'
}
export const WORKFLOW_METRICS_V3 = Object.entries(WORKFLOW_METRIC_LABELS_V3).map(([value, label]) => ({ label, value }));

export const getMetricLabel = (value) => {
  const metric = WORKFLOW_METRICS.find(m => m.value === value);
  if (metric) {
    return metric.label;
  }
  return 'Unknown Metric';
}

export const STRUCTURE_TYPES = [
  { label: 'Instruction', value: 'instruction' },
  { label: 'Wraparound Services (Counseling, Support Programs, Textbook Programs, etc.)', value: 'student_services' },
  { label: 'Budgeting and Administration (HR, Purchasing, Processes, etc.)', value: 'business_services' },
  { label: 'General Operations (A&R, Parking, Campus Policing, etc.)', value: 'general_operations' },
  { label: 'Other', value: 'other' }
];

export const ACADEMIC_YEAR_COHORTS_V3 = [{
  title: 'Fall 2022 Cohort',
  subtitle: '(Comprehensive Ed Plan by 6/30/2023)'
}, {
  title: 'Spring 2023 Cohort',
  subtitle: '(Comprehensive Ed Plan by 12/31/2023)'
}, {
  title: 'Fall 2023 Cohort',
  subtitle: '(Comprehensive Ed Plan by 6/30/2024)'
}, {
  title: 'Spring 2024 Cohort',
  subtitle: '(Comprehensive Ed Plan by 12/30/2024)'
}];

export const COMP_ED_COLUMNS_V3 = [{
  title: 'Total Number of Enrolled Students in Cohort',
  attr: 'enrolled_students_total',
  type: 'number'
}, {
  title: '# of Students Who Received a Comprehensive Ed Plan by End of First Primary Term',
  attr: 'primary_term_students_num',
  type: 'number'
}, {
  title: '% of Students Who Received a Comprehensive Ed Plan by End of First Primary Term',
  attr: 'primary_term_students_pct',
  type: 'percent'
}, {
  title: '# of Students Who Received a Comprehensive Ed Plan by End of First Academic Year',
  attr: 'academic_year_students_num',
  type: 'number'
}, {
  title: '% of Students Who Received a Comprehensive Ed Plan by End of First Academic Year',
  attr: 'academic_year_students_pct',
  type: 'percent'
}];

export const SEP_V3_METRIC_TEMPLATE = {
  [WORKFLOW_METRIC_STEPS.SUCCESSFUL_ENROLLMENT]: {
    metricName: WORKFLOW_METRIC_LABELS_V3[WORKFLOW_METRIC_STEPS.SUCCESSFUL_ENROLLMENT],
    baselineYear: 2023,
    additionalGoals1: ' as well as goals related to the Vision 2030 Outcome and Benchmark stated below:',
    visionOutcome: {
      text: 'Increase with equity the number of students attending a California community college, with particular emphasis on the number of undeserved Californians.',
      title: 'Goal 2, Equity in Access; Outcome 4—Student Participation',
    },
    benchmarks: `<strong>Benchmark</strong>: By 2030, increase with equity the number of students attending a California community college by either a) 25% or
      b) so their enrollments are higher than prior to the pandemic for student populations that experienced enrollment declines during the pandemic (whichever is greater),
      with emphasis on reaching underserved populations of Californians.`,
    additionalGoals2: 'aligned with the above Vision 2030 Outcome and Benchmark for Student Participation',
    additionalGoalsExample: 'successful enrollment for our student veterans by 25%',
    keyStrategiesTitle: '',
    keyStrategiesExample: `Establish and deploy data-driven systematic case management system for successful enrollment for all the identified DI student groups,
      with an initial primary focus on Black or African American students.`,
    additionalKeyStrategiesExample: `To help increase overall Successful Enrollment, our college will increase the number of dual enrollment agreements
      with feeder schools to at least a total of 10 school districts by Spring 2028.`
  },
  [WORKFLOW_METRIC_STEPS.COMPLETED_MATH_ENGLISH]: {
    metricName: 'Completed Transfer-Level Math and English',
    fullMetricName: WORKFLOW_METRIC_LABELS_V3[WORKFLOW_METRIC_STEPS.COMPLETED_MATH_ENGLISH],
    alternateMetricName: 'Completion of Transfer-Level Math and English',
    baselineYear: 2023,
    tableFooter: `Data for just Transfer-Level Math and just Transfer-Level English can be found here on DataVista:
      <a href="https://datavista.cccco.edu/data_views/single_metric_first_time_nsa" class="text-link" target="_blank">Data Vista: Data View - Single Metric – First-Time NSA Cohort</a>.`,
    additionalGoals1: ` - as well as more specific completion goals for Transfer-Level Math, Transfer-Level English, and/or ESL Student Completion of Transfer-Level English.
      Overall, the Completion Transfer-Level Math and English supports the related Vision 2030 Outcome and Benchmark on Completion stated below:`,
    visionOutcome: {
      text: 'Increase with equity the number of California community college students who complete a meaningful educational outcome.',
      title: 'Goal 1, Equity in Success; Outcome 1—Completion',
    },
    benchmarks: `<strong>Benchmark</strong>: By 2030, increase with equity the number of California community college students completing a certificate,
      associate or baccalaureate degree by 30%.`,
    additionalGoals2: 'for Transfer-Level Math, English, and/or ESL Student Completion of Transfer-Level English that support the above Vision 2030 Outcome and Benchmark.',
    additionalGoalsExample: 'the Transfer-Level Math and English completion rate for our overall student population from 76% to 85%',
    keyStrategiesTitle: 'Transfer-Level Math & English',
    keyStrategiesExample: `Establish and deploy data-driven systematic case management system and student support outreach—involving
      instructional faculty, counselors, and classified professionals—to improve completion
      of transfer-level Math and English for all the identified DI student groups.`,
    additionalKeyStrategiesExample: `Create community of practice for English and Math attainment for all instructors and tutor support,
      with a focus on data, inclusive teaching and support strategies, co-requisite refinement,
      and cross-campus relationship building to strategically increase Transfer-Level Math and English completion for the overall student population.`
  },
  [WORKFLOW_METRIC_STEPS.PERSISTENCE]: {
    metricName: 'Persistence',
    fullMetricName: WORKFLOW_METRIC_LABELS_V3[WORKFLOW_METRIC_STEPS.PERSISTENCE],
    alternateMetricName: 'Student Persistence',
    baselineYear: 2022,
    additionalGoals1: `. Increasing equitable Persistence supports the related Vision 2030 Outcome and Benchmark on Completion stated below:`,
    visionOutcome: {
      text: 'Increase with equity the number of California community college students who complete a meaningful educational outcome.',
      title: 'Goal 1, Equity in Success; Outcome 1—Completion',
    },
    benchmarks: `<strong>Benchmark</strong>: By 2030, increase with equity the number of California community college students completing a certificate,
      associate or baccalaureate degree by 30%.`,
    additionalGoals2: 'for Persistence that support the above Vision 2030 Outcome and Benchmark.',
    additionalGoalsExample: 'our overall persistence of first primary term to secondary term from 72% to 85%',
    keyStrategiesTitle: 'Student Persistence',
    keyStrategiesExample: `Create robust, systematic proactive support outreach for all the identified DI student groups,
      with an initial primary focus on homeless students,
      to connect them with all campus resources (including basic needs) and enrollment support for the second semester.`,
    additionalKeyStrategiesExample: `Our college will ensure all students, especially new incoming and returning students,
      receive proactive messaging throughout their first semester (and beyond)
      to promote all available resources for students via all communication methodologies
      (email, social media, and traditional mailings to students’ listed mailing address)
      prior to the start of each semester. Prioritization of personalized outreach will center on all disproportionately impacted students.`
  },
  [WORKFLOW_METRIC_STEPS.COMPLETION]: {
    metricName: 'Completion',
    baselineYear: 2020,
    additionalGoals1: `, including those aligned with the Vision 2030 Outcome and Benchmarks stated below:`,
    visionOutcome: {
      text: `(I) Increase with equity the number of California community college students who complete a meaningful educational outcome.
        (II) Increase with equity the number of California community college students who earn an associate degree for transfer.`,
      title: 'Goal 1, Equity in Success; Outcome 1—Completion, Outcome 2b—Baccalaureate Attainment',
    },
    benchmarks: `<strong>Benchmark</strong>: By 2030, increase with equity the number of California community college students completing a certificate,
      associate or baccalaureate degree by 30%.
      <br/><strong>Benchmark</strong>: By 2030, increase with equity the number of California community college students who earn an associate degree for transfer by 30%.`,
    additionalGoals2: 'for Completion aligned with the above Vision 2030 Outcome and Benchmarks.',
    additionalGoalsExample: 'the completion rate for our students with disabilities by 40%',
    keyStrategiesExample: `Build data-driven, systematic case management centered on early alert, counseling,
      and early educational planning for all disproportionately impacted students,
      with an initial primary focus on Black and African American students.`,
    additionalKeyStrategiesExample: `Establish a degree auditing system with auto-awarding,
      and campaign with students after 45 units to ensure they are enrolled in courses that will help them cross the finish line.
      Prioritization of personalized outreach will center on all disproportionately impacted students.`
  },
  [WORKFLOW_METRIC_STEPS.TRANSFER]: {
    metricName: 'Transfer',
    fullMetricName: WORKFLOW_METRIC_LABELS_V3[WORKFLOW_METRIC_STEPS.TRANSFER],
    baselineYear: 2019,
    additionalGoals1: `, including those aligned with the Vision 2030 Outcome and Benchmarks stated below:`,
    visionOutcome: {
      text: `(I) Increase with equity the number of California community college students who transfer to CSU or UC.
        (II) Increase with equity the number of California community college students who transfer to non-profit private/independent four-year institutions.`,
      title: 'Goal 1, Equity in Success; Outcomes 2d and 2e—Baccalaureate Attainment',
    },
    benchmarks: `<strong>Baseline Benchmark</strong>: By 2030, increase with equity the number of California community college students
      who transfer to a UC or CSU consistent with the rate of enrollment growth in those systems.
      <br/><strong>Stretch Benchmark</strong>: With intersegmental collaboration and cooperation, by 2030,
      increase with equity the number of California community college students who transfer to a UC or CSU by 20%.`,
    additionalGoals2: 'for Transfer aligned with the above Vision 2030 Outcome and Benchmarks.',
    additionalGoalsExample: 'the transfer rate for our overall student population from 34% to 45%',
    keyStrategiesExample: `Build data-driven, systematic case management centered on transfer processes and support for all disproportionately impacted students,
      with an initial primary focus on students with disabilities.`,
    additionalKeyStrategiesExample: `Our college will increase our number of ADTs and ensure every student who has completed 30 units or more
      are scheduled with a counselor and/or complete a transfer workshop.`
  }
};

export const VISION_2030_DOC = `https://www.cccco.edu/-/media/CCCCO-Website/docs/report/Vision-2030-A-Roadmap-for-California-Community-Colleges.pdf`;