import { trigger, style, transition, animate } from '@angular/animations';

export const slideOverAnimation = trigger('slideOver', [
  transition(':enter', [
    style({ width: 0, 'overflow-x': 'hidden', 'white-space': 'nowrap' }),
    animate('150ms ease-out', style({ width: '*' }))
  ]),
  transition(':leave', [
    style({ width: '*', 'overflow-x': 'hidden', 'white-space': 'nowrap' }),
    animate('150ms ease-in', style({ width: 0 }))
  ])
]);

export const slideOverSlowAnimation = trigger('slideOverSlow', [
  // transition(':enter', [
  //   style({ width: 0, 'overflow-x': 'hidden', 'white-space': 'nowrap' }),
  //   animate('250ms ease-out', style({ width: '*' }))
  // ]),
  transition(':leave', [
    style({ width: '*', 'overflow-x': 'hidden', 'white-space': 'nowrap' }),
    animate('250ms ease-in', style({ width: 0 }))
  ])
]);
