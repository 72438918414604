import { IEntitiesState, Query, Queries, Root, Model, combineRootFactory } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { Institution } from '@app-models';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IEntitiesState>('Entities');

/**
 * Predefined queries to run against root states.
 */
export interface IEntitiesQueries {
  getLead: Query<Model.Member>;
  getActiveMembers: Query<{ [duration_id: number]: Array<Model.Member> }>;
  getMembers: Query<{ [duration_id: number]: Array<Model.Member> }>;
  getCurrentMember: Query<Model.Member>;
  getCurrentMemberInstitutionId: Query<number>;
  get: Query<IEntitiesState>;
  getLeadInfo: Query<Model.MemberInfo>;
}

Queries.Entities = {
  getLead: fromRoot(state => state.lead),
  getActiveMembers: fromRoot(state => {
    return Object.keys(state.members).reduce((active_members, duration_id) => {
      active_members[duration_id] = state.members[duration_id].filter(member => member.is_active);
      return active_members;
    }, {});
  }),
  getMembers: fromRoot(state => state.members),
  getCurrentMember: fromRoot(state => {
    if (state.program_year && state.current_member_institution_id && state.current_duration_id) {
      return _.find(state.members[state.current_duration_id], (m: Model.Member) => m.institution_id === state.current_member_institution_id);
    } else {
      return null;
    }
  }),
  getCurrentMemberInstitutionId: fromRoot(state => state.current_member_institution_id),
  get: fromRoot(state => ({ ...state })),
  getLeadInfo: fromRoot(state => ({
    program_year: state.program_year,
    direct_funding: state.direct_funding,
    institution_id: state.lead.institution_id,
    proposal_id: state.lead.proposal_id,
    name: Institution.getLongNameWithId(state.lead.institution),
    shortName: state.lead.institution ? state.lead.institution.name : undefined
  })),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Entities: Query<IEntitiesState>;
  }

  interface Queries {
    Entities: IEntitiesQueries;
  }
}
