<div class="full-page-modal" [class.minimal]="minimal" [class.full-page-modal--small]="small" [class.full-page-modal--medium]="medium" poFocusTrap>
  <div class="full-page-modal__container">

    <header class="full-page-modal__header">
      <h2 class="full-page-modal__heading" id="qa_full_page_modal_heading">
        <icon *ngIf="headingIcon" [iconId]="headingIcon.iconId" class="margin-right-xs" [ngClass]="headingIcon.class"></icon>
        {{heading}}
      </h2>
      <button
        id="qa_modal_dismiss_button"
        title="Close"
        type="button"
        class="action-button action-button--secondary icon-only"
        (click)="dismissModal('dismiss')"
        [disabled]="buttonCancelDisabled">
        <icon iconId="close"></icon>
      </button>
    </header>

    <div class="full-page-modal__body" id="qa_full_page_modal_body">
      <ng-content></ng-content>
    </div>

    <footer *ngIf="showFooter || !minimal" class="full-page-modal__footer">
      <div class="columns columns--2">
        <div class="full-page-modal__footer__left">
          <button
            *ngIf="!!buttonBack"
            id="qa_modal_back_button"
            type="button"
            class="action-button action-button--secondary icon--left"
            (click)="goBack()"
            [disabled]="buttonBackDisabled">
            <icon iconId="arrow-back"></icon>
            {{buttonBack}}
          </button>
          <button
            *ngIf="!buttonSecondaryActionHide"
            id="qa_modal_secondary_action_button"
            type="button"
            class="action-button action-button--tertiary"
            (click)="doSecondaryAction()">
            {{buttonSecondaryAction}}
          </button>
        </div>
        <div class="full-page-modal__footer__right">
          <button
            *ngIf="!!buttonCancel"
            id="qa_modal_cancel_button"
            type="button"
            class="action-button action-button--secondary"
            (click)="cancelModal()"
            [disabled]="buttonCancelDisabled">
            {{buttonCancel}}
          </button>
          <a *ngIf="buttonLink" id="qa_modal_button_link"
            class="action-button action-button--primary"
            [routerLink]="buttonLink.route"
            (click)="dismissModal('dismiss')">
            {{buttonLink.label}}
          </a>
          <button
            *ngIf="!!buttonAction"
            id="qa_modal_action_button"
            type="submit"
            class="action-button action-button--primary"
            [class.loading]="buttonActionLoading"
            [class.icon--right]="buttonActionIconId"
            (click)="doAction('action')"
            [disabled]="buttonActionDisabled">
            {{buttonAction}}
            <icon *ngIf="buttonActionIconId && !buttonActionLoading" [iconId]="buttonActionIconId"></icon>
            <div class="loader-spinner-inline"></div>
          </button>
        </div>
      </div>
    </footer>

  </div>
</div>
