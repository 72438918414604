import { Component, Input, OnInit, OnChanges, AfterViewInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Model, State, Queries } from '@app-ngrx-domains';
import { Subject, from, Subscription, combineLatest } from 'rxjs';
import { collapsibleCardAnimation } from '@app-generic/animations';
import { takeUntil, debounceTime, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SurveyService } from '..';
import { findIndex, sortBy } from 'lodash';
import { INSTITUTION_TYPES, QUESTION_TYPES, SURVEY_UTILS } from '@app/shared.surveys/consts';
import { LookupService } from '@app/core/services';

@Component({
  selector: 'survey-template',
  templateUrl: './survey-template.component.html',
  providers: [SurveyService],
  animations: [collapsibleCardAnimation]
})
export class ReportingSurveyComponent implements OnChanges, AfterViewInit, OnDestroy {

  @Input() canEdit: boolean;
  @Input() set survey(s: Model.EASurveyTemplate) {
    const doInit = !this._survey;
    this._survey = s;
    setTimeout(() => {
      if (doInit) {
        this.onInit();
      }
    });
  }
  get survey(): Model.EASurveyTemplate {
    return this._survey;
  }
  @Input() allowUnpublished: boolean;
  @Input() proposalId: number;
  @Input() institutionId: number;
  @Input() durationId: number;
  @Input() effortAreaId: number;
  @Input() fundId: number;
  @Input() userId: number;
  @Input() reportingYear: number;
  @Input() isPreview: boolean; // Enable preview mode
  @Input() overrideVersion: number;
  @Input() hideQuestionNumbers: boolean;
  @Input() isLead: boolean;
  @Input() isBudgeted: boolean;
  @Output() checkValidation: EventEmitter<null> = new EventEmitter()

  public surveyVersion: number;
  public useCurrentProposalStore: boolean;
  public questionTypes = QUESTION_TYPES;
  public proposal: Model.ProposalItem;
  public files: Array<Model.Document> = [];

  private _survey: Model.EASurveyTemplate;
  private initialized: boolean;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    public surveyService: SurveyService,
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupService
  ) {}

  onInit() {
    this.surveyVersion = this.overrideVersion || this.survey.latest_version;

    if (this.proposalId) {
      combineLatest([
        this.store.select(Queries.Proposals.getAll),
        this.store.select(Queries.CurrentProposal.get),
        this.store.select(Queries.Files.getItems)
      ]).pipe(
        map(([proposals, proposal, files]) => [proposals.find(p => p.id === this.proposalId), proposal, files]),
        takeUntil(this.destroy$)
      ).subscribe(([p1, p2, files]) => {
        let proposal = p1;
        if (!p1) {
          proposal = p2;
          this.useCurrentProposalStore = true;
        }

        this.proposal = proposal;
        this.files = files;

        this.initialize();
        this.checkValidation.emit();
      });
    } else if (this.isPreview) {
      this.initialized = true;
      this.surveyService.initialize({
        canEdit: false,
        isPreview: true,
        hideQuestionNumbers: this.hideQuestionNumbers
      });
    }
  }

  initialize() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    const responses = this.proposal['survey_responses'] ? [].concat(this.proposal['survey_responses']) : [];
    const response = responses.find(r =>
      (this.institutionId ? this.institutionId === r.institution_id : true) &&
      (this.durationId ? this.durationId === r.duration_id : true) &&
      (this.effortAreaId ? this.effortAreaId === r.parent_effort_area_id : true) &&
      (this.fundId ? this.fundId === r.fund_id : true)
    );

    let question;
    if (response) {
      // Use survey version of existing response
      question = this.survey.survey_questions.find(q => q.id === response.question_id);
    }

    this.surveyVersion = question ? question.survey_version : undefined;

    if (!this.surveyVersion) {
      // Use the latest survey version
      this.surveyVersion = this.survey.latest_version;
    }

    this.initService();
  }

  ngOnChanges() {
    if (this.initialized) {
      this.initService();
    }
  }

  initService() {
    this.surveyService.initialize({
      canEdit: this.canEdit,
      useCurrentProposalStore: this.useCurrentProposalStore,
      proposalId: this.proposalId,
      institutionId: this.institutionId,
      durationId: this.durationId,
      effortAreaId: this.effortAreaId,
      fundId: this.fundId,
      userId: this.userId,
      isPreview: this.isPreview,
      hideQuestionNumbers: this.hideQuestionNumbers
    });
  }

  ngAfterViewInit() {
    if (!this.hideQuestionNumbers && this.surveyQuestions.length < 2) {
      setTimeout(() => {
        this.hideQuestionNumbers = true;
      });
    }

    let statusSubscription: Subscription;

    from(this.surveyService.questions).pipe(
      debounceTime(250),
      takeUntil(this.destroy$)
      ).subscribe(() => {
        // Anytime we add/remove a question, run validation
        this.checkValidation.emit();
        this.cdr.detectChanges();
    });
  }

  get showAsDraft() {
    return !(this.survey.is_published || this.allowUnpublished) && !this.isPreview && (this.surveyVersion === this.survey.latest_version);
  }

  get filteredSurveyQuestions() {
    return this.survey.survey_questions.filter(question => {
      return (this.surveyVersion === question.survey_version) &&
        (this.isLead || (question.institution_filter !== INSTITUTION_TYPES.LEAD)) &&
        (this.isBudgeted || (question.institution_filter !== INSTITUTION_TYPES.BUDGETED));
    });
  }

  get surveyQuestions() {
    return this.filteredSurveyQuestions.filter(question => !question.follow_up_to && !question.group_id).sort(function(a, b) {
      return a.question_number - b.question_number;
    });
  }

  followUpQuestions(question: Model.EASurveyQuestion) {
    if (![QUESTION_TYPES.RADIO, QUESTION_TYPES.CHECKBOX, QUESTION_TYPES.DROPDOWN].includes(question.question_type)) {
      return;
    }

    return this.filteredSurveyQuestions
      .filter(q => q.follow_up_to === question.id)
      .filter(q => {
        // check for conditional display
        const questions = this.surveyService.questions.value;
        if (this.isPreview || !q.if_answer_is || !questions) {
          return true;
        } else {
          const questionRef = questions.find(qRef => qRef.id === question.id);
          if (questionRef) {
            if (SURVEY_UTILS.isMultiSelectQuestion(question)) {
              const answerOption = question.response_options.find(choice => choice.title === q.if_answer_is);
              if (answerOption) {
                return questionRef.getResponse(answerOption.id);
              }
            } else {
              const value = questionRef.getResponse();
              return q.if_answer_is === String(value);
            }
          }
        }
      }).sort(function(a, b) {
      let multiSort = 0;

      // Sort the questions by order of response options first
      if (SURVEY_UTILS.isMultiSelectQuestion(question)) {
        const options = sortBy(question.response_options, ['sort_order']);
        const choiceIndexA = findIndex(options, ['title', a.if_answer_is]);
        const choiceIndexB = findIndex(options, ['title', b.if_answer_is]);
        multiSort = choiceIndexA - choiceIndexB;
      }

      return multiSort || a.question_number - b.question_number;
    });
  }

  questionsForGroup(question: Model.EASurveyQuestion) {
    return this.filteredSurveyQuestions.filter(q => q.group_id === question.id);
  }

  checkQuestionValidity() {
    if (this.proposal) {
      const validator = this.surveyService.createValidator(this.filteredSurveyQuestions, this.proposal.survey_responses, this.files);
      return validator.validateResponses();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  trackById(index: number, question: Model.EASurveyQuestion) {
    return question.id;
  }
}
