<div class="page" [ngClass]="pageClass" [ngClass]="{'archival': printMode}" [@.disabled]="disableAnimations">
  <ng-container *ngIf="showEnvWarning" [ngSwitch]="!!isProduction">
    <div *ngSwitchCase="true" class="site-nav__environment  site-nav__environment--warning">
      Production Environment
    </div>

    <div *ngSwitchCase="false" class="site-nav__environment">
      Safe Environment: <span class="site-nav__environment-name">{{environment.name}}</span>
    </div>
  </ng-container>

  <div *ngIf="showMaintenanceWarning" class="system-notification system-notification--info no-print" [ngClass]="systemNotificationClass" [@slideInOut]>
    <icon iconId="error"></icon>
    <p [innerHTML]="maintenanceStatus.warning_message | safeHtml"></p>
    <button *ngIf="showSystemNotificationExpander" type="button"
      (click)="systemNotificationExpanded = !systemNotificationExpanded; updateNotificationClass()" class="system-notification__toggle text-button">
      View {{ systemNotificationExpanded ? "Less" : "More" }}
    </button>
  </div>

  <div class="page-wrapper" [ngClass]="pageWrapperClass" [@slideInOut]="pushDownPageWrapper" poPopupAnchor>
    <button *ngIf="!printMode" type="button" class="skip-link" (click)="focusOnMainContent()">Skip to main content</button>
    <alert *ngIf="idling && !timedOut && !appErrorMessage" level="warning" button-dismiss="Stay Logged In" (dismiss)="idleReset()">
      <p>You have not been active for a while. You will be logged out in {{formatCountdown()}}. </p>
    </alert>

    <alert *ngIf="timedOut && !appErrorMessage" level="info" button-dismiss="Ok" (dismiss)="idleReset()">
      <p>You have been logged out due to inactivity. </p>
    </alert>

    <alert *ngIf="appErrorMessage" level="{{appErrorLevel}}" button-dismiss="{{refreshOnError ? 'Refresh' : 'Ok'}}" (dismiss)="clearAppError()">
      <p [innerHtml]="appErrorMessage | replaceNewline | safeHtml"></p>
      <p *ngIf="showHelpdeskLink">If the problem persists, please report it to the <a href="/help" (click)="clearAppError(true)">NOVA Help Desk</a>.</p>

      <p *ngIf="showDetails">{{errDetailsMessage}}</p>
      <p *ngIf="hasDetails" class="text-link" (click)="showDetails = !showDetails">{{ showDetails ? 'Hide Details' : 'Show Details'}}</p>
    </alert>

    <ng-container #appModalWrapper></ng-container>

    <span *ngIf="!printMode" defaultOverlayTarget></span>
    <div class="busy-spinner-overlay" *ngIf="showBusySpinner">
      <app-spinner [isBusy]="true"></app-spinner>
    </div>

    <div *ngIf="!printMode" class="secondary-content no-print">
      <app-left-nav class="site-nav__container"></app-left-nav>
    </div>

    <div #mainContent class="main-content" role="main">
      <content-header class="content-header" [printMode]="printMode"></content-header>

      <div class="content-body" poPopupAnchor>

        <app-nav-workflow *ngIf="isWorkflowVisible"></app-nav-workflow>

        <div class="content-body__tabs no-print" [class.comments]="isCommentsOpen">
          <error-stepper></error-stepper>
          <button *ngIf="isCommentingAvailable && !isCommentsOpen" class="tab-section action-button action-button--small comments-tab" [@slideOver] (click)="openComments()">
            <icon iconId="message"></icon>
            <span>Comments</span>
          </button>
        </div>

        <div class="content-body__container" [class.visible]="isWorkflowVisible && !printMode" [class.comments]="isCommentsOpen && !printMode">


          <div id="scroll-anchor" class="content-body__scroll-anchor"></div>
          <comment-widget *ngIf="isCommentingAvailable && isCommentsOpen"></comment-widget>
          <router-outlet></router-outlet>

          <div class="title-header__status-actions no-print">
            <div class="title-header__actions">
              <app-action-bar [actions]="footerActions"></app-action-bar>
            </div>
          </div>

          <div *ngIf="showFooterLogo" class="content-footer">
            <img src="../../../assets/images/{{logo.src}}" id="qa_footer_logo_image" class="margin-bottom-xs" alt="{{logo.altText}}" width="335" height="70" />
            <p class="copyright" id="qa_footer_copyright_year">{{year}} &copy; California Community Colleges<br>NOVA Site Version: <a class="text-link" routerLink="releaseNotes"> {{ getClientVersion() }}</a></p>
          </div>
        </div>

        <app-comments></app-comments>

      </div>
    </div>
    <helpdesk-widget class="no-print" *ngIf="showHelpDeskWidget" [user]="helpdeskUser"></helpdesk-widget>
  </div><!-- END | .page-wrapper -->
</div><!-- END | .page -->
